.FirstScreen{
    background: #2F58F2;
    width:750px;
    margin-bottom: 120px;
    max-width: 750px;
    overflow-x: hidden;

}
.Logo{
    display:flex;
    position: absolute;
    left:47px;
    top:25px;
    text-align: left;
    justify-content: flex-start;
    z-index: 4;
    align-items: center;
}
.Logo img{
    width: 157px;
    height: 57px;
}
.Logo img.logo2{
    margin-left: 10px;
    width: 142px;
    height: 65px;
}
.FirstScreen .sky{
    position: relative;
}
.FirstScreen .front{
    position: relative;
    z-index: 2;
    margin-top: -392px;
}

.meter{
    position: absolute;
    left:0;top:0;
    z-index: 1;
    transform: translate(0,-588px);
    animation: MeterFall infinite 4s linear ;
}


@keyframes MeterFall {
    0%{
        opacity:1;
        transform: rotate(-40deg) translate(0,-388px);
    }
    100%{
        opacity:0;
        transform: rotate(-40deg) translate(0px,588px);
    }
}


.FirstScreen .content{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;left:0;right:0;
    z-index: 3;
}
.FirstScreen .h1{
    font-size: 65px;
    font-weight: 900;
    color: #FFFFFF;
    letter-spacing: 10.1px;
    margin-top: 496px;
}
.FirstScreen.type2 .h1{
    margin-top: 484px;
}

.FirstScreen .element{
    width: 265px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity:0;
    transition: opacity 1s linear;
}
.FirstScreen .element.show{
    width: 265px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity:1;
    animation: FloatingAround infinite 3.4s linear ;
}
@keyframes FloatingAround {
    0%{
        transform: rotate(0deg) translate(0,0);
    }
    25%{
        transform: rotate(5deg) translate(0px,10px);
    }
    50%{
        transform: rotate(0deg) translate(10px,10px);
    }
    75%{
        transform: rotate(-5deg) translate(10px,0px);
    }
    100%{
        transform: rotate(0deg) translate(0,0);
    }
}


.FirstScreen .p1{
    font-size: 26px;
    color: #FFFFFF;
    font-weight: 900;
    letter-spacing: 0.08px;
}
.FirstScreen .p2{
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0.08px;
    line-height: 20px;
}

.FirstScreen .element1{
    left:290px;
    top:783px;
    animation-delay:1s;
}
.FirstScreen .element2{
    left:40px;
    top:882px;
    animation-delay:2s;
}
.FirstScreen .element3{
    left:222px;
    top:1026px;
    animation-delay:3s;
}
.FirstScreen .element4{
    right:40px;
    top:943px;
}

.FirstScreen .airplane{
    display: flex;
    position: absolute;
    top:484px;
    right:104px;
    z-index: 3;
    animation: FloatingPlane 0.541s linear infinite alternate;
    /*animation: FloatingPlane infinite 0.1s linear ;*/

}
@keyframes FloatingPlane {
    0%{
        transform: rotate(0deg) translate(0,0);
    }
    50%{
        transform: rotate(-15deg) translate(0px,0px);
    }
}
