

.MenuBtn{
    display:flex;
    position: fixed;
    right:0;
    top:55%;
    text-align: right;
    justify-content: flex-end;
    z-index: 9;
}
.MenuBtn.fst2{
    top:800px;
}
.MenuBtn img{
    width:100px;
    height: 100px;
    margin-right: 40px;
}


.MainPage{
    background: transparent;
    width: 750px;
    display:flex;
    flex-direction: column;
    align-items: center;
    color: #030303;
    text-align: left;
    font-weight: normal;
    /*letter-spacing: 4px;*/
    position: relative;
    font-family: Arial, sans-serif;
}

.MainPage .h1 {
    color: #030303;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    font-weight: bolder;
}
.MainPage .h2{
    color: #030303;
    font-size: 32px;
    line-height: 48px;
    font-weight: bolder;
    text-align: center;
}
.MainPage .h3{
    color: #030303;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    font-weight: bolder;
    margin-top: 10px;
}

.MainPage .primaryBtn{
    width: 205px;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3C56F8;
    -webkit-border-radius: 53px;
    -moz-border-radius: 53px;
    border-radius: 53px;
    font-size: 30px;
    color:#fff;
    align-self: center;
    margin:auto;
    text-decoration: none;
}
.MainPage .primaryBtn.disable{
    background: #B9B9B9;
    font-size: 30px;
    color: #FFFFFF;
}


/* --- -- - ----  */

.sectionYdbh {
    width:750px;
    padding-top: 30px;
    margin-bottom: 90px;
    text-align: center;

}
.sectionYdbh .titleImg{
    margin:60px auto 0;
    width:670px;
    text-align: center;
}


/* --- -- - ----  */

.sectionRhgb{
    width: 750px;
    padding-top: 30px;
    margin-bottom: 90px;

    background: transparent url("./res/bg1.png") 80% 0% no-repeat;
}
.rhgbRow{
    margin-top: 30px;
    display:flex;
    flex-direction: row;
    width:750px;
    overflow: scroll;
}

.rhgbLi{
    /*margin:0 0 0 13px;*/
    margin-right: 0px;
    margin-left: 38px;
    text-decoration: none;
}
.rhgbLi.rhgbLiEnd{
    padding-right: 38px;
}
/* --- -- - ----  */
.sectionChengJiu{
    width: 750px;
    margin-top: 30px;
    margin-bottom: 90px;
    background: transparent url("./res/bg2.png") 0% 0 no-repeat;
}
.chengJiuGrid{
    margin-top: 60px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.chengJiuGrid img{
    margin:0 19px 40px;
}
.chengjiuDownload{
    height:337px;
    display:flex;
    /*justify-content: space-around;*/
    flex-direction: column;
    align-items: center;
    background: transparent url("./res/bg3.png") center 0 no-repeat;
}
.chengjiuDownload .h2{
    padding-top: 90px;
}
/* --- -- - ----  */
.sectionTy{
    width:750px;

    padding-top: 30px;
    padding-bottom: 90px;
    background: #f2f2f2;
}
/* --- -- - ----  */
.sectionAbout{
    padding-top: 120px;
    margin-bottom: 90px;
    width:750px;
    background: transparent url("./res/bg4.png") right 0 no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ourRow{
    margin: 60px auto 120px;
    width:676px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.ourRow img{
    display:flex;
}
.aboutQrRow{
    margin: 60px auto 100px;
    display: flex;
    flex-direction: row;
    width:676px;
    justify-content: space-around;

}
.aboutQrRow .qrLi{
    display:flex;
    flex-direction: column;
    align-items: center;
    /*margin:0 20px;*/
}
.aboutQrRow .qrLi img{
    width: 200px;
    height:200px;
}
/* --- -- - ----  */

.pageFooter{
    width:750px;
    height:200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #212121;
    color: #ffffff;
    font-size: 20px;
}
